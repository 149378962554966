/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ClientFormUserRoleNames, ClientFormUserRoleKeys, ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import { FormType, FormTypeKeys } from '../../models/FormTypes';
import {
  Placeholders,
  UserPlaceholder,
  TranslatablePlaceholderValue,
  ModulePlaceholder,
  FormPlaceholder,
  ImportJobPlaceholder,
  CommentPlaceholder,
  GroupPlaceholder,
} from '../../models/Placeholders';
import { RiskStatus, RiskStatusKeys } from '../../models/Risk';
import { systemDefaultLanguageCode } from '../../types/Languages';
import DateUtils from '../DateUtils';
import { Option } from '../../components/Option';
import LanguageUtils from '../LanguageUtils';

interface Change {
  fieldName: string;
  before: string | number | Owner;
  after: string | number | Owner;
}

interface Owner {
  id: string;
  name: string;
}

// pascal case without {}'s - bug in BE code
const altPlaceholderFormat = (input: string) => {
  const startsWithBracket = input.charAt(0) === '{';
  return input.charAt(startsWithBracket ? 1 : 0).toLocaleLowerCase() + input.trim().substring(startsWithBracket ? 2 : 1, input.length - 1);
};

export const interpolate = (
  text: string,
  placeholderData: Record<string, any>,
  toJsx: (
    placeholder: string,
    placeholderData: any,
    key: number,
    allPlaceholders: Record<string, any>,
  ) => ReactElement | string = defaultInterpolationReplacer,
  placeholderRegex = /\{([\S]+?)}/g,
): Array<ReactElement | string> => {
  if (!text || text.length === 0) {
    return [];
  }

  const textPlaceholders = String(text).match(placeholderRegex);

  let result: Array<ReactElement | string> = [text];
  if (textPlaceholders) {
    let index = 0;
    for (const placeholder of textPlaceholders) {
      let placeholderDataValue =
        placeholderData[placeholder] === undefined ? placeholderData[altPlaceholderFormat(placeholder)] : placeholderData[placeholder];

      if (!placeholderDataValue) {
        const placeholderParts = placeholder.split('.');
        if (placeholderParts.length > 1) {
          placeholderDataValue = placeholderData[placeholderParts[0] + '}'];
        }
      }

      const replacementValue = toJsx(placeholder, placeholderDataValue, index, placeholderData);
      result = result
        .map((part) => {
          // For each string part we have, try replace placeholders in it
          if (typeof part === 'string') {
            const temp: Array<ReactElement | string> = part.split(placeholder);

            // we have a placeholder, so put it at index 1
            // since we split it on the placeholder, it should be where the placeholder was
            if (temp.length > 1) {
              temp.splice(1, 0, replacementValue);
            }

            return temp;
          }

          // Wasn't a string part
          return part;
        })
        .flat();

      index++;
    }
  }

  return result.filter((x) => !!x);
};

const defaultInterpolationReplacer = (placeholder: string, placeholderData: any, key: number) => {
  if (
    // EffectiveDate can be null
    (placeholderData === null && placeholder !== Placeholders.EFFECTIVE_DATE) ||
    // TYPE has a fallback
    (placeholderData === undefined && placeholder !== Placeholders.TYPE)
  ) {
    return placeholder;
  }

  switch (placeholder) {
    case Placeholders.RISK_FIELD: {
      return (
        <span key={key} className="font-medium">
          {<RiskPropertyRenderer propertyName={(placeholderData as any).change.fieldName} key={key} />}
        </span>
      );
    }
    case Placeholders.RISK_PREVIOUS_VALUE: {
      const probabilityOptions = placeholderData.probabilityOptions as Option<string, string>[];
      const impactOptions = placeholderData.impactOptions as Option<string, string>[];

      let value = placeholderData.change.before;

      switch (placeholderData.change.fieldName) {
        case 'MatrixHorizontalId':
          value = probabilityOptions.find((x) => x.id === value)?.text || value;
          break;
        case 'MatrixVerticalId':
          value = impactOptions.find((x) => x.id === value)?.text || value;
          break;
        case 'Status':
          value = <RiskStatusRenderer status={value as RiskStatus} key={value} />;
          break;
        default:
          break;
      }

      return (
        <span key={key} className="font-medium">
          {value}
        </span>
      );
    }
    case Placeholders.RISK_CURRENT_VALUE: {
      const probabilityOptions = placeholderData.probabilityOptions as Option<string, string>[];
      const impactOptions = placeholderData.impactOptions as Option<string, string>[];

      let value = placeholderData.change.after;

      switch (placeholderData.change.fieldName) {
        case 'MatrixHorizontalId':
          value = probabilityOptions.find((x) => x.id === value)?.text || value;
          break;
        case 'MatrixVerticalId':
          value = impactOptions.find((x) => x.id === value)?.text || value;
          break;
        case 'Status':
          value = <RiskStatusRenderer status={value as RiskStatus} key={value} />;
          break;
        default:
          break;
      }

      return (
        <span key={key} className="font-medium">
          {value}
        </span>
      );
    }
    case Placeholders.RISK_FLAGGED: {
      const riskData = Object.entries(placeholderData).filter(([key, _]) => {
        return ['impactOptions', 'probabilityOptions'].indexOf(key) === -1;
      });
      const probabilityOptions = placeholderData.probabilityOptions as Option<string, string>[];
      const impactOptions = placeholderData.impactOptions as Option<string, string>[];
      return (
        <ul key={key} className="ml-6 list-disc">
          {riskData.map(([propertyName, value]) => {
            switch (propertyName) {
              case 'probability':
                value = probabilityOptions.find((x) => x.id === value)?.text || value;
                break;
              case 'impact':
                value = impactOptions.find((x) => x.id === value)?.text || value;
                break;
              case 'owner':
                value = (value as Owner).name;
                break;
              case 'status':
                value = <RiskStatusRenderer status={value as RiskStatus} key={propertyName} />;
                break;
              default:
                break;
            }
            return (
              <li key={propertyName}>
                <RiskPropertyRenderer propertyName={propertyName} />: <span className="font-medium">{value as string}</span>
              </li>
            );
          })}
        </ul>
      );
    }
    case Placeholders.RISK_UPDATED_MULTIPLE: {
      const changes = Object.entries(placeholderData.changes as Change[]);
      const probabilityOptions = placeholderData.probabilityOptions as Option<string, string>[];
      const impactOptions = placeholderData.impactOptions as Option<string, string>[];
      return (
        <ul key={key} className="ml-6 list-disc">
          {changes.map(([propertyName, change]) => {
            let beforeValue: string | number | Owner | ReactElement = change.before;
            let afterValue: string | number | Owner | ReactElement = change.after;
            switch (propertyName) {
              case 'matrixHorizontalId':
                beforeValue = probabilityOptions.find((x) => x.id === beforeValue)?.text || beforeValue;
                afterValue = probabilityOptions.find((x) => x.id === afterValue)?.text || afterValue;
                break;
              case 'matrixVerticalId':
                beforeValue = impactOptions.find((x) => x.id === beforeValue)?.text || beforeValue;
                afterValue = impactOptions.find((x) => x.id === afterValue)?.text || afterValue;
                break;
              case 'owner':
                beforeValue = (beforeValue as Owner).name;
                afterValue = (afterValue as Owner).name;
                break;
              case 'status': {
                const riskStatusBefore = beforeValue as RiskStatus;
                const riskStatusAfter = afterValue as RiskStatus;
                beforeValue = <RiskStatusRenderer status={riskStatusBefore} key={riskStatusBefore} />;
                afterValue = <RiskStatusRenderer status={riskStatusAfter} key={riskStatusAfter} />;
                break;
              }
              default:
                break;
            }
            return (
              <li key={propertyName}>
                <RiskSingleChangeRenderer
                  propertyName={propertyName}
                  beforeValue={beforeValue as string | ReactElement}
                  afterValue={afterValue as string | ReactElement}
                />
              </li>
            );
          })}
        </ul>
      );
    }
    case Placeholders.RISK_REASON_FOR_CHANGE_OWNER:
    case Placeholders.RISK_REASON_FOR_CHANGE_MULTIPLE:
    case Placeholders.RISK_REASON_FOR_CHANGE_SINGULAR: {
      return (
        <div key={key}>
          <RiskReasonForChangeRenderer reason={placeholderData.reasonForChange} />
        </div>
      );
    }
    case Placeholders.RISK_OWNER_BEFORE: {
      return (
        <span key={key} className="font-medium">
          {placeholderData.owner.before.name}
        </span>
      );
    }
    case Placeholders.RISK_OWNER_AFTER: {
      return (
        <span key={key} className="font-medium">
          {placeholderData.owner.after.name}
        </span>
      );
    }
    case Placeholders.USER: {
      return (
        <span key={key} className="font-medium">
          {placeholderData}
        </span>
      );
    }
    case Placeholders.PRIMARY_USER:
    case Placeholders.SECONDARY_USER: {
      const { userName } = placeholderData as UserPlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          {userName}
        </span>
      );
    }
    case Placeholders.FORM_ROLE: {
      const role = isNaN(placeholderData)
        ? `form-role.${placeholderData as ClientFormUserRoleNames}`
        : ClientFormUserRoleKeys[placeholderData as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]];
      return <FormRoleRenderer role={role} key={key} />;
    }
    case Placeholders.SUB_TITLE:
    case Placeholders.START_DATE:
    case Placeholders.DUE_DATE:
    case Placeholders.FORM_COUNT:
    case Placeholders.FILE_NAME:
    case Placeholders.STATUS: {
      const text = placeholderData as string;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          {text}
        </span>
      );
    }

    case Placeholders.EFFECTIVE_DATE: {
      const effectiveDate = placeholderData ? new Date(placeholderData) : null;
      return <EffectiveDateRenderer effectiveDate={effectiveDate} key={key} />;
    }

    case Placeholders.FORM_SECTION: {
      const title = placeholderData as TranslatablePlaceholderValue<string>;
      return (
        <span className="text-color-1 font-medium" key={key}>
          <TranslatablePlaceholderValueRenderer value={title} />
        </span>
      );
    }
    case Placeholders.DUE_ON_DATE: {
      const dueDate = new Date(placeholderData);
      return <DueOnDateRenderer dueDate={dueDate} key={key} />;
    }
    case Placeholders.REVIEW_PERIOD_TYPE: {
      const reviewPeriodType = placeholderData as DateInterval;
      return <PeriodRenderer interval={reviewPeriodType} key={key} />;
    }
    case Placeholders.REVIEW_PERIOD: {
      const reviewPeriod = placeholderData as number;
      return <span>{reviewPeriod}</span>;
    }
    case Placeholders.MODULE: {
      const { name } = placeholderData as ModulePlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          <TranslatablePlaceholderValueRenderer value={name} />
        </span>
      );
    }
    case Placeholders.FORM: {
      const { title } = placeholderData as FormPlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          <TranslatablePlaceholderValueRenderer value={title} />
        </span>
      );
    }
    case Placeholders.IMPORT_JOB: {
      const { formTitle } = placeholderData as ImportJobPlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          <TranslatablePlaceholderValueRenderer value={formTitle} />
        </span>
      );
    }
    case Placeholders.COMMENT: {
      const { text } = placeholderData as CommentPlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          {text}
        </span>
      );
    }
    case Placeholders.TYPE: {
      return <FormTypeRenderer type={placeholderData} key={key} />;
    }
    case Placeholders.GROUP: {
      const name = placeholderData as GroupPlaceholder;
      return (
        <span className="[:not(.no-color)_>_&]:text-color-1 font-medium" key={key}>
          {LanguageUtils.getTranslation('name', name)}
        </span>
      );
    }
    case Placeholders.NUMBER_OF_MEMBERS: {
      return <span key={key}>{placeholderData}</span>;
    }
    default: {
      return placeholder;
    }
  }
};

const FormRoleRenderer: FC<{ role: string }> = (props) => {
  const { role } = props;
  const { t } = useTranslation('common');

  return <span className="text-color-1 font-medium">{t(role as any) as string}</span>;
};

const EffectiveDateRenderer: FC<{ effectiveDate: Date | null }> = (props) => {
  const { effectiveDate } = props;
  const { t } = useTranslation('form');
  return (
    <span className="[:not(.no-color)_>_&]:text-color-1 font-medium">
      {effectiveDate ? DateUtils.formatDate(effectiveDate) : t('effective-date.after-completion')}
    </span>
  );
};

const DueOnDateRenderer: FC<{ dueDate: Date }> = (props) => {
  const { dueDate } = props;
  const { t } = useTranslation('common');

  return <span>{DateUtils.overDueText(dueDate, t)}</span>;
};

const PeriodRenderer: FC<{ interval: DateInterval }> = (props) => {
  const { interval } = props;
  const { t } = useTranslation(['common']);
  return <span>{t(DateIntervalKeys[interval])}</span>;
};

const FormTypeRenderer: FC<{ type: (typeof FormType)[keyof typeof FormType] }> = (props) => {
  const { type } = props;
  const { t } = useTranslation(['common']);
  return <span>{t(type === FormType.Document ? FormTypeKeys[FormType.Document] : FormTypeKeys[FormType.Asset])}</span>;
};

const riskPropertyTranslations = {
  matrixHorizontalId: 'risk:form-labels.probability',
  matrixVerticalId: 'risk:form-labels.impact',
  status: 'risk:form-labels.status',
  owner: 'risk:form-labels.owner',
  ownerId: 'risk:form-labels.owner',
  ownerName: 'risk:form-labels.owner',
  title: 'risk:form-labels.title',
  description: 'risk:form-labels.description',
  reasonForChange: 'risk:form-labels.change-reason',
  probability: 'risk:form-labels.probability',
  impact: 'risk:form-labels.impact',
} as const;

const RiskPropertyRenderer: FC<{ propertyName: string }> = ({ propertyName }) => {
  // Convert the property name to lower case with the first letter in lower case
  propertyName = (propertyName.charAt(0).toLowerCase() + propertyName.slice(1)) as keyof typeof riskPropertyTranslations;

  const { t } = useTranslation(['risk']);

  if (!(propertyName in riskPropertyTranslations)) {
    return <span>{propertyName}</span>;
  }

  return <span>{t((riskPropertyTranslations as any)[propertyName])}</span>;
};

const RiskStatusRenderer: FC<{ status: RiskStatus }> = ({ status }) => {
  const { t } = useTranslation(['risk']);

  return <span>{t(RiskStatusKeys[status])}</span>;
};

const RiskReasonForChangeRenderer: FC<{ reason: string }> = ({ reason }) => {
  const { t } = useTranslation(['risk']);

  return (
    <>
      <div className="text-gray-2 font-medium">{t('form-labels.change-reason')}</div>
      <div>{reason}</div>
    </>
  );
};

const RiskSingleChangeRenderer: FC<{
  propertyName: string;
  beforeValue: string | ReactElement;
  afterValue: string | ReactElement;
}> = ({ propertyName, beforeValue, afterValue }) => {
  const { t } = useTranslation(['risk']);
  return (
    <span>
      <Trans
        t={t}
        i18nKey="risk:single-change"
        components={{
          TranslatedProperty: <RiskPropertyRenderer propertyName={propertyName} />,
          After:
            typeof afterValue === 'string' ? <span className="font-medium">{afterValue}</span> : <span className="font-medium">{afterValue}</span>,
          Before:
            typeof beforeValue === 'string' ? <span className="font-medium">{beforeValue}</span> : <span className="font-medium">{beforeValue}</span>,
        }}
      />
    </span>
  );
};

const TranslatablePlaceholderValueRenderer: FC<{ value: TranslatablePlaceholderValue<string> }> = (props) => {
  const {
    i18n: { language },
  } = useTranslation();

  // Legacy items are strings, not objects
  if (typeof props.value === 'string') {
    return <>{props.value}</>;
  }

  const targetLangValue = props.value?.[language];
  const fallbackValue = props.value?.[systemDefaultLanguageCode];

  return <>{targetLangValue || fallbackValue}</>;
};
