import { ClientFormSectionStatus } from './../models/ClientFormSectionStatus';
import { ApiResponse } from '../models/ApiResponse';
import {
  ClientForm,
  ActivityPagedFilter,
  ClientFormProgressResponse,
  ClientFormRequest,
  ClientFormSectionInfo,
  FormReferenceDirection,
  SignatureDetails,
  Attachment,
  SignResponse,
} from '../models/ClientForm';
import { FormListItem } from '../models/Form';
import { ClientFormUser, ClientFormUserDto } from '../models/ClientFormUser';
import { Descriptor } from '../models/Descriptor';
import BaseService from './BaseService';
import { ClientFormUserStats } from '../models/ClientFormUserStats';
import { ClientFormShare } from '../models/ClientFormShare';
import ClientFormVersionHistory from '../models/ClientFormVersionHistory';
import User from '../models/User';
import { AxiosRequestConfig } from 'axios';
import { DocumentListItem, DocumentResponse } from '../models/Document';
import { Access } from '../models/Access';
import { AssociationListItem } from '../models/Association';
import { FormType } from '../models/FormTypes';

export default class ClientFormService extends BaseService {
  public static allocateToClient(payload: ClientFormRequest): Promise<ApiResponse<ClientForm>> {
    return this.post('/v1/forms', payload, { ignoredErrorStatusCodes: [403] });
  }

  public static allocateToClientBulk(payload: ClientFormRequest[]): Promise<ApiResponse<ClientForm[]>> {
    return this.post('/v1/forms/bulk', payload);
  }

  public static getForms(clientId: string, filter: ActivityPagedFilter = {}): Promise<ApiResponse<FormListItem[]>> {
    return this.get('/v1/forms', { params: { clientId: clientId, ...filter } });
  }

  public static getUserFormStats(formTypes: (typeof FormType)[keyof typeof FormType][]): Promise<ApiResponse<ClientFormUserStats>> {
    return this.get(`/v1/forms/user-stats`, { params: { formTypes } });
  }

  public static getFormStats(
    clientId: string,
    formTypes: (typeof FormType)[keyof typeof FormType][],
  ): Promise<ApiResponse<Record<string, ClientFormUserStats>>> {
    return this.get('/v1/forms/stats', { params: { clientId, formTypes } });
  }

  public static getFormsPaged(
    clientId: string,
    filter: ActivityPagedFilter = {},
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<FormListItem[] | DocumentListItem[]>> {
    return this.post('/v1/forms/paged', { ClientId: clientId, ...filter }, config);
  }

  public static getFormsDataWarehousePaged(
    clientId: string,
    filter: ActivityPagedFilter = {},
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<FormListItem[]>> {
    return this.post('/v1/forms/paged-dw', { ClientId: clientId, ...filter }, config);
  }

  public static getForm(formId: string, ignoredErrorStatusCodes?: number[]): Promise<ApiResponse<ClientForm | DocumentResponse>> {
    return this.get<ClientForm>(`/v1/forms/${formId}`, { ignoredErrorStatusCodes });
  }

  public static getFormProgress(formId: string): Promise<ApiResponse<ClientFormProgressResponse>> {
    return this.get(`/v1/forms/${formId}/progress`);
  }

  public static forceProgressRecalculation(formId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/force-progress-recalculation`);
  }

  public static deleteForm(formId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/forms/${formId}`);
  }

  public static deleteFormBulk(formIds: string[]): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/forms/bulk-delete`, formIds);
  }

  public static archiveForm(formId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/archive`);
  }

  public static restoreForm(formId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/unarchive`);
  }

  public static getFormInfo(formId: string): Promise<ApiResponse<Descriptor>> {
    return this.get(`/v1/forms/${formId}/info`);
  }

  public static updateForm(
    formId: string,
    accessType: Access,
    dueDateUtc: Date | null,
    effectiveDateUtc: Date | null,
    subtitle: string,
    clientModuleId?: string | undefined,
    clientModuleSectionId?: string | undefined,
  ): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}`, {
      dueDateUtc: dueDateUtc,
      subtitle: subtitle,
      clientModuleId: clientModuleId,
      clientModuleSectionId: clientModuleSectionId,
      accessType: accessType,
      effectiveDateUtc,
    });
  }

  public static completeForm(formId: string, remark: string | null = null): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/complete`, { remark });
  }

  public static submitForm(formId: string, remark: string | null = null): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/submit`, { remark });
  }

  public static approveForm(formId: string, remark: string | null = null, signatureDetails?: SignatureDetails): Promise<ApiResponse<SignResponse>> {
    return this.put(`/v1/forms/${formId}/approve`, { remark, ...(signatureDetails || {}) });
  }

  public static validateForm(formId: string, remark: string | null = null, signatureDetails?: SignatureDetails): Promise<ApiResponse<SignResponse>> {
    return this.put(`/v1/forms/${formId}/validate`, { remark, ...(signatureDetails || {}) });
  }

  public static rejectForm(formId: string, remark: string | null = null): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/reject`, { remark });
  }

  public static reopenForm(
    formId: string,
    remark: string | null = null,
    dueDateUtc: Date | null = null,
    effectiveDateUtc: Date | null = null,
  ): Promise<ApiResponse<string>> {
    return this.put(`/v1/forms/${formId}/reopen`, { remark, dueDateUtc, effectiveDateUtc });
  }

  public static getReferences(formId: string, direction: FormReferenceDirection): Promise<ApiResponse<AssociationListItem[]>> {
    return this.get(`/v1/forms/${formId}/references`, { params: { direction } });
  }

  public static createReference(formId: string, targetIds: string[], isFormAnswer: boolean): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/references`, { targetIds: targetIds, isFormAnswer });
  }

  public static removeReference(formId: string, targetFormId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/forms/${formId}/references/${targetFormId}`);
  }

  public static updateRisk(formId: string, riskScore: number, riskQuestions: number): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/risk`, { formRisk: riskScore, formRiskQuestions: riskQuestions });
  }

  public static changeSectionStatus(formId: string, sectionId: string, status: ClientFormSectionStatus): Promise<ApiResponse<ClientFormSectionInfo>> {
    return this.post(`/v1/forms/${formId}/sections/${sectionId}/status`, { status });
  }

  //#region Users

  public static getFormUsers(formId: string, ignoredErrorStatusCodes?: number[]): Promise<ApiResponse<ClientFormUserDto[]>> {
    return this.get(`/v1/forms/${formId}/users`, { ignoredErrorStatusCodes });
  }

  public static addOrUpdateFormUsers(formId: string, dto: ClientFormUserDto[], sectionId?: string): Promise<ApiResponse<ClientFormUser[]>> {
    return this.post(`/v1/forms/${formId}/users${sectionId ? '/sections/' + sectionId : ''}`, dto);
  }

  public static removeFormUser(formId: string, userId: string): Promise<ApiResponse<ClientFormUser[]>> {
    return this.delete(`/v1/forms/${formId}/users`, { params: { userId: userId } });
  }

  public static getCurrentFormUser(formUsers: ClientFormUser[], currentUser: User, sectionId: string | null = null): ClientFormUser | null {
    const updatedFormUsers = formUsers.map((formUser) => {
      const otherRoles = formUsers
        .filter((user) => user.id === formUser.id && user.role !== formUser.role)
        .map((user) => ({ role: user.role, requiresAction: user.requiresAction }));
      return { ...formUser, otherRoles };
    });
    return updatedFormUsers.find((user) => user.id === currentUser.id && (user.formSectionId || null) === sectionId) || null;
  }

  //#endregion

  public static updateVisibility(formId: string, isVisible: boolean): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/forms/${formId}/visibility`, JSON.stringify(isVisible), { headers: { 'Content-Type': 'application/json' } });
  }

  //#region Shares

  public static getFormShares(formId: string): Promise<ApiResponse<ClientFormShare[]>> {
    return this.get(`/v1/forms/${formId}/shares`);
  }

  public static updateFormShares(formId: string, clientIds: string[]): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/forms/${formId}/shares`, { clientIds: clientIds });
  }

  //#endregion

  public static getUserHistories(formId: string): Promise<ApiResponse<ClientFormVersionHistory[]>> {
    return this.get(`/v1/forms/${formId}/users/history`);
  }

  public static getTasksPaged(clientId: string, filter: ActivityPagedFilter, config?: AxiosRequestConfig): Promise<ApiResponse<FormListItem[]>> {
    return this.get('/v1/users/tasks-paged', { params: { clientId: clientId, ...filter }, ...config });
  }

  public static async getExport(formId: string): Promise<Blob> {
    const res = await this.get<Blob>(`/v1/forms/${formId}/pdf`, { responseType: 'arraybuffer' });

    if (res.status !== 200) {
      throw res.data;
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];

    return new Blob([fileContent], { type: fileType });
  }

  public static getAttachments(clientFormId: string): Promise<ApiResponse<Attachment[]>> {
    return this.get(`/v1/forms/${clientFormId}/files`);
  }

  public static addAttachment(clientFormid: string, fileId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/forms/${clientFormid}/files/${fileId}`);
  }
  public static deleteAttachment(clientFormId: string, fileId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/forms/${clientFormId}/files/${fileId}`);
  }
}
